import {
  SITE_PROPERTIES_SITE_LANGUAGE,
  LANGUAGES_PATH,
  MAIN_LANGUAGE,
  ADD_LANGUAGES_PATH,
  GET_LANGUAGES_PATH,
  SUPPORTED_LANGUAGES_URL_SERVERLESS,
} from './topology';
import {HttpClient, HttpResponse} from '@wix/http-client';
import {ISiteLanguageAndLocalResponse} from '../types/wix-types';
import {
  SiteTranslatableProperties,
  GetSiteTranslatablesPropertiesRequest,
} from '@wix/ambassador-multilingual-sitetranslator-v2-site-translatable-properties/types';
import {getSiteTranslatablesProperties} from '@wix/ambassador-multilingual-sitetranslator-v2-site-translatable-properties/http';

export type LanguageId = string;

export interface ISiteLanguageAndLocale {
  languageId: string;
  localeId: string;
  countryCode?: string;
  autoTranslate?: string;
  status?: string;
}

export interface ILanguageStatus {
  code: string;
  locale: string;
  name?: string;
  status?: 'Active' | 'Inactive';
  autoTranslate?: 'ON' | 'OFF';
}

export interface ILanguagesStatus {
  data: {
    originalLanguage: ILanguageStatus[];
    translationLanguages: ILanguageStatus[];
  };
}

export class OnBoardingApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }
  
  public async getSiteTranslatablesProperties(
    options: GetSiteTranslatablesPropertiesRequest
  ): Promise<SiteTranslatableProperties> {
    const res = await this.httpClient.request(getSiteTranslatablesProperties(options));
    if (!res.data) {
      throw new Error(`response from server has no data. response status: ${res.status}`);
    }
    if (!res.data.data) {
      throw new Error(`response from server has no data`);
    }

    return res.data.data;
  }

  public getLanguages() {
    return this.httpClient.get(SUPPORTED_LANGUAGES_URL_SERVERLESS);
  }

  public getLanguagesStatus(instanceId: string): Promise<HttpResponse<ILanguagesStatus>> {
    return this.httpClient.get(LANGUAGES_PATH, {
      params: {'instance-id': instanceId},
    });
  }

  public getAllLanguages(instance: string): Promise<HttpResponse<ILanguagesStatus>> {
    return this.httpClient.get(GET_LANGUAGES_PATH);
  }

  public setMainLanguage(
    {
      mainLanguageId,
      mainLanguageLocaleId,
      mainLanguageFlag,
    }: {
      mainLanguageId: string;
      mainLanguageLocaleId: string;
      mainLanguageFlag: string;
    },
    instanceId: string
  ) {
    return this.httpClient.post(
      MAIN_LANGUAGE,
      wrapWithDTO({
        code: mainLanguageId,
        locale: mainLanguageLocaleId,
        countryCode: mainLanguageFlag,
      }),
      {
        params: {'instance-id': instanceId},
      }
    );
  }

  async getSiteLanguage(msid: string): Promise<LanguageId> {
    const {data}: {data: ISiteLanguageAndLocalResponse} = await this.httpClient.get(
      `${SITE_PROPERTIES_SITE_LANGUAGE}?fields.paths=language&metasiteId=${msid}`
    );
    return data.properties.language;
  }

  async addSecondaryLanguage({
    languageId,
    localeId,
    countryCode,
    autoTranslate,
    status,
  }: ISiteLanguageAndLocale): Promise<void> {
    await this.httpClient.post(ADD_LANGUAGES_PATH, {
      id: languageId,
      code: languageId,
      locale: localeId,
      countryCode,
      primary: false,
      status,
      autoTranslate,
    });
  }

  async changeMainLanguage(
    {languageId, localeId, countryCode}: ISiteLanguageAndLocale,
    instanceId: string
  ): Promise<void> {
    await this.httpClient.put(
      MAIN_LANGUAGE,
      wrapWithDTO({
        code: languageId,
        locale: localeId,
        countryCode,
      }),
      {
        params: {'instance-id': instanceId},
      }
    );
  }
}

export function wrapWithDTO(data: object) {
  return {
    data,
  };
}
